/* 
* Template Name             : Al-Abbar Trading & Investment
* Start Date                : 7-4-2022
* End Date                  : 11-4-2022
* Last Update               : 11-4-2022
* Version                   : 1.0.0
* URL                       : https://mohammedmodather2021.github.io/al-abbar-ui
* GitHub URL                : https://github.com/MohammedModather2021/al-abbar-ui.git
* Contact US                : mohammedmodather144@gmail.com
*/
/* 
* 1- General Style
   1.0- Universal Selector
   1.1- Root Element
   1.2- Body Element
* 2- Section Style
   2.0- Navbar
   2.1- Hero
   2.2- About
   2.3- Activities
   2.4- Gallery
   2.5- Footer
* 3- Common Style
   3.0- Single Swiper
   3.1- Multi Swiper
   3.2- Scroll To Top
   3.3- Loading Overlay 
   3.4- Scroll Bar
   3.5- Text Error 
/* 
/** 1- Genral Style */
/**  1.0- Universal Selector */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/** 1.1- Root Element */
:root{
  --colorWhite:#FFFFFF;
  --colorBlue:#43286D;
  --colorSecondary:#ECECEC;
  --colorPrimary:#B3257A;
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}
/** 1.2- Body Element */
body{
  font-family: 'Inter', sans-serif;
  overflow: hidden !important;
}
/** 2- Section Style */
/**  2.0- Navbar */
.navbar .navbar-brand img{
  width: 85px;
}
.navbar .navbar-collapse .navbar-nav .nav-link{
  color: #f8dceb;
  width: fit-content;
  font-size: .9rem;
}
.navbar .navbar-collapse .navbar-nav .nav-link:not(:last-of-type) svg{
  display: none;
}
.navbar .navbar-collapse .navbar-nav .nav-link.active{
  color: #FFF;
}
.navbar .navbar-collapse .navbar-nav .lang{
  border: 1px solid var(--colorSecondary);
  width: fit-content;
  display: inline-block;
  font-size: .9rem;
  color: var(--colorWhite);
  cursor: pointer;
}
.navbar .navbar-collapse .navbar-nav .lang:hover{
  border: 1px solid var(--colorSecondary);
  background-color: var(--colorSecondary);
  color: var(--colorBlue);
}
/** 2.1- Hero */
.hero{
  background-image: url(../../public/assets/images/hero/bg-hero.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.hero,
.hero .container{
  min-height: 100vh; 
}
.hero .title{
  color: var(--colorWhite);
  line-height: 1.6;
  font-size: 1.4rem;
}
.hero .title span{
  color: var(--colorPrimary);
} 
/** 2.2- About */
.about .title span{
  color: var(--colorPrimary);
}
.about .icon{
  flex-basis: 100px;
}
.about .desc{
  width: 80%;
  font-size: .9rem;
  line-height: 1.8;
}
.about .about-wrapper{
  background-color: var(--colorBlue);
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;
}
.about .about-wrapper .icon{
  margin-top: -1.2rem;
}
/** 2.3- Activities */
.activities{
 background-image: url(../../public/assets/images/activities/bg-activities.svg);
 background-size: cover;
 background-repeat: no-repeat;
}
.activities .box{
  border: 2px solid transparent;
  padding: 1rem;
  background-color: transparent;
  transition: background-color .5s ease-in-out border .5s;
  -webkit-transition: background-color .5s ease-in-out border .5s;
  -moz-transition: background-color .5s ease-in-out border .5s;
  -ms-transition: background-color .5s ease-in-out border .5s;
  -o-transition: background-color .5s ease-in-out border .5s;
}
.activities .box:hover {
  background-color: #f8dceb;
  border: 2px solid rgba(179, 37, 122,.3);
}
.activities .box .icon img{
  width: 100px;
}
.activities .box .info .name{
  font-size: 1.4rem;
}
.activities .box:hover .info .name{
  color: var(--colorBlue);
}
.activities .box .info .desc{
  font-size: .9rem;
  line-height: 1.8;
  text-align: justify;
  text-align-last: center;
}
/** 2.4- Gallery */
.gallery{
  background-image: url(../../public/assets/images/gallery/bg-gallery.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center left;
}
.gallery .single-swiper.swiper {
  width: 70vw !important;
}
.gallery .single-swiper .swiper-wrapper .swiper-slide img{
  height: 470px !important;
  object-fit: cover;
}
.gallery .single-swiper .swiper-pagination-bullet{
  height: 15px;
  width: 15px;
  background-color: #f8dceb;
  border: 2px solid #222;
  opacity: 1;
}
.gallery .single-swiper .swiper-pagination-bullet-active{
  background-color: var(--colorPrimary);
  border-color: var(--colorPrimary);
}
/** 2.5- Customers */
.customers .multi-swiper .swiper-wrapper .swiper-slide img{
  height: 100px;
  width: 100%;
  object-fit: contain;
}
/** 2.6- Footer */
.footer{
  background-color: var(--colorSecondary);
}
.footer .info li a{
  font-size: .9rem;
  text-decoration: none;
  color: var(--colorBlue);
}
.footer .info li svg{
  color: var(--colorPrimary);
}
.footer .social-links li svg{
  padding: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 35px;
  padding: .3rem;
  text-align: center;
  background-color: var(--colorPrimary);
  color: var(--colorWhite);
}
/** All Activities */
.all-activities{
  background-image: url(../../public/assets/images/hero/bg-hero.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.all-activities .box{
  height: 100%;
  background-color: var(--colorBlue);
  border-top-left-radius: .8rem;
  border-top-right-radius: .8rem;
}
.all-activities .box .info .desc{
  font-size: .85rem;
  line-height: 1.8;
}
.all-activities .box .image img{
  height: 250px;
}
.all-activities .single-swiper .swiper-wrapper .swiper-slide img{
  height: 300px;
}
.all-activities .single-swiper.swiper{
  height: 64%;
}
/** 3- Common Style */
/**  3.0- Single Swiper */
.single-swiper.swiper {
  width: 40vw;
  height: 50%;
  overflow: hidden;
}
.single-swiper .swiper-wrapper .swiper-slide img{
  height: 390px;
  width: 100%;
  padding: 0px;
  object-fit: cover;
  background-color: var(--colorSecondary);
  border: 1px solid var(--colorSecondary) !important ;
  border-radius: .8rem;
  -webkit-border-radius: .8rem;
  -moz-border-radius: .8rem;
  -ms-border-radius: .8rem;
  -o-border-radius: .8rem;
}
.single-swiper .swiper-pagination-bullet{
  height: 15px;
  width: 15px;
  background-color: var(--colorSecondary);
  border: 2px solid #020101;
  opacity: 1;
}
.single-swiper .swiper-pagination-bullet-active{
  background-color: #020101;
}
.single-swiper .swiper-button-next,
.single-swiper .swiper-button-prev{
  background-color: #46344a;
  color: var(--colorSecondary);
  width: 35px;
  height: 35px;
  border-radius: .4rem;
  -webkit-border-radius: .4rem;
  -moz-border-radius: .4rem;
  -ms-border-radius: .4rem;
  -o-border-radius: .4rem;
}
.single-swiper .swiper-button-next::after, 
.single-swiper .swiper-button-prev::after{
  font-size:.9rem;
  font-weight: bold;
}
/** 3.1- Multi Swiper */
.multi-swiper.swiper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
/** 3.2- Scroll To Top */
#scroll-top{
  display: none;
  width: 40px;
  height: 40px;
  text-align: center;
  color: white;
  background: var(--colorPrimary);
  position: fixed;
  z-index: 9999;
  right: 15px;
  line-height: 37px;
  bottom: 10px;
  cursor: pointer;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
#scroll-top:hover{
  background: var(--colorPrimary);
}
/** 3.3- Loading Overlay */
.loading-overlay{
  color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: var(--colorPrimary);
  width: 100%;
  height: 100%;
  z-index: 99999;
}
.spinner {
  top: 25%;
  margin: 100px auto;
  width: 70px;
  text-align: center;
  font-size: 14px;
  height: 40px;
  position: relative;
}
.spinner > div {
  background-color: #ffffff;
  height: 100%;
  width: 6px;
  margin-inline-start: 2.4px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
} 
.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}  
.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}  
.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
} 
.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}
/** 3.4- Scroll Bar */
::-webkit-scrollbar {
  background-color: var(--colorBackground);
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--colorPrimary);
  border-radius: 7px
}
::-moz-scrollbar {
  background-color: var(--colorBackground);
  width: 8px;
}
::-moz-scrollbar-thumb {
  background-color: var(--colorPrimary);
  border-radius: 7px;
}
::-o-scrollbar {
  background-color: var(--colorBackground);
  width: 8px;
}
::-o-scrollbar-thumb {
  background-color: var(--colorPrimary);
  border-radius: 7px;
}
/** 3.5- Text Error */
.text-error{
  text-align: center;
  color: red;
}