@media screen and (max-width:767px) {
  .navbar-nav{
    padding-inline: .5rem;
    padding-bottom: .5rem;
    background-color: var(--colorPrimary);
    border-radius: .5rem;
    -webkit-border-radius: .5rem;
    -moz-border-radius: .5rem;
    -ms-border-radius: .5rem;
    -o-border-radius: .5rem;
  }
  .navbar .navbar-collapse .navbar-nav .nav-link:not(:last-of-type) svg{
    display: inline-block;
  }
  .navbar-toggler:focus{
    box-shadow: unset;
  }
  .navbar-toggler {
    background-color: var(--colorPrimary);
    color: var(--colorWhite);
    padding: 0.5rem 0.7rem;
    border-radius: 0.5rem;
  }
  .hero .title {
    margin-top: 3rem;
    text-align: center;
    width: 100%;
    font-size: 1.5rem;
  }
  .gallery .single-swiper.swiper,
  .single-swiper.swiper{
    width: 90vw !important;
    margin-block: 1rem;
  }
  .about .about-wrapper{
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
  }
  .about .desc{
    width: 100%;
  }
  .footer .info li{
    margin-bottom: .5rem;
  }
  .all-services .box{
    margin-bottom: 3rem;
  }
  .all-activities .single-swiper .swiper-wrapper .swiper-slide img{
    height: 200px;
  }
  .all-activities .single-swiper.swiper{
    height: 58%;
  }
  .all-activities .swiper-horizontal>.swiper-pagination-bullets, .all-activities .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
    bottom: 10px;
  }
  .all-activities .box{
    height: auto;
  }
}